<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <NavButton />
        <v-breadcrumbs v-if="!haveRole('ROLE_PROVEEDOR')">
          <template v-for="(item, index) in breadcrumbs">
            <v-breadcrumbs-item
              v-if="item.show"
              :key="index"
              :href="item.href"
              :disabled="item.disabled"
            >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-card-title>
      <v-divider />
      <v-card-text >
          <v-row>
            <template>
              <v-col cols="12" lg="6" v-if="haveRole('ROLE_PROVEEDOR')">
                <AgregarAccionistaV2Component />
              </v-col>
              <v-col cols="12" :lg="haveRole('ROLE_PROVEEDOR') ? 6 : 12">
                <BeneficiarioFinal ref="beneficiarioFinalRef" />
              </v-col>
            </template>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
                    <v-icon class="mr-1">mdi-account-group-outline</v-icon>
                    Lista de accionistas
                  </h6>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <TablaAccionistas />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import TablaAccionistas from './components/TablaAccionistasComponent.vue'
import AgregarAccionistaV2Component from './components/AgregarAccionistaV2Component.vue'
import formNomina from './components/FormNominaComponent.vue'
import NavButton from '@/components/utils/NavButton.vue'
import BeneficiarioFinal from './components/BeneficiarioFinal.vue'

export default {
  name: 'agregarAccionistaView2',
  components: {
    AgregarAccionistaV2Component,
    TablaAccionistas,
    formNomina,
    NavButton,
    BeneficiarioFinal,
  },
  data: () => ({}),
  computed: {
    ...mapState('proveedores', ['provider']),
    ...mapState(["userInfo"]),
    breadcrumbs () {
      return [
        {
          show: this.haveRole("ROLE_SUPERVISOR") ? true : false,
          text: 'RUPES /',
          disabled: false,
          href: `/rupes`
        },
        {
          show: true,
          text: this.provider?.nombre,
          disabled: false,
          href: `/proveedor/${this.$route.params.idProveedor}`
        },
        {
          show: true,
          text: '/ Agregar Accionistas y representante legal',
          disabled: true
        }
      ]
    },
    idUsuarioProveedor () {
      return this.$route.params.idProveedor ? Number(this.$route.params.idProveedor) : null;
    },
  },
  methods: {
    ...mapActions('proveedores', ['getProvider']),
    regresar () {
      // Navegar a la ruta anterior
      this.$router.back()
    },
    async existeBeneficiariosProveedor() {
      const {
        data: { beneficiarios },
      } = await this.services.Proveedores.existeBeneficiariosProveedor({
        id_usuario: this.idUsuarioProveedor,
      });

      if (!beneficiarios) {
        this.$refs.beneficiarioFinalRef.$v.$touch();
        this.temporalAlert({
          show: true,
          message: "Debe agregar al menos un beneficiario final",
          type: "warning",
        });
      }
    },
  },
  async created () {
    await this.getProvider(this.idUsuarioProveedor)
    if (this.haveRole('ROLE_PROVEEDOR')){
      await this.existeBeneficiariosProveedor()
    }
  }
}
</script>
<style lang=""></style>
